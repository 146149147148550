<template>
  <div class="modal-card" data-test-id="vmc-upsell-modal">
    <div class="modal-content">
      <div class="box">
        <section v-if="!enquiryOpen">
          <div class="has-text-right">
            <a class="has-text-danger" @click="$modal.close">Close</a>
          </div>
          <div class="section">
            <div class="content has-text-centered">
              <img
                :src="require('core/img/icons/car-odometer.svg')"
                width="300"
              />
            </div>
            <p class="title is-4 has-text-primary">{{ title }}</p>
            <p>{{ text }}</p>
          </div>
          <div class="buttons is-right">
            <button @click="toggleEnquiry" class="button is-rounded is-soft">
              Get started
            </button>
          </div>
        </section>
        <section v-else>
          <BusinessEnquiry />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VmcUpsell',
  components: {
    BusinessEnquiry: () =>
      import('@/modules/contact/components/cazana/BusinessEnquiry')
  },
  data: () => ({ enquiryOpen: false }),
  computed: {
    title() {
      return 'Looking for an easy and efficient way to independently validate the mileage history of a vehicle?'
    },
    text() {
      return 'If you intend to retail a vehicle then we advise that you complete a Vehicle Mileage Check. If you would like to discuss the addition of this service to your existing account, then please contact your Experian account manager, call our Business Support team on 0870 411 1200 or click the button below to leave your details.'
    }
  },
  methods: {
    toggleEnquiry() {
      this.enquiryOpen = !this.enquiryOpen
    }
  }
}
</script>
